export default {
  map: {
    bounds: [[11.913879081, 62.897090095], [14.413961129, 64.159866432]],
    style: 'mapbox://styles/kolizz/clc25a0mi003v15plhtacns4v'
  },
  sources: {
    'dim': {
      'type': 'geojson',
      'data': './geodata/dim.geojson'
    },
    'features': {
      'type': 'geojson',
      'data': './lifts_slopes.geojson',
    },
    'weather_stations': {
      'type': 'geojson',
      'data': './weather_stations.geojson'
    }
  },
  baseLayers: [
    {
      'id': 'dim',
      'type': 'fill',
      'source': 'dim',
      'layout': {},
      'paint': {
        'fill-color': '#fff',
        'fill-opacity': 0.8
      }
    },
    {
      'id': 'dim-line',
      'type': 'line',
      'source': 'dim',
      'layout': {},
      'paint': {
        'line-color': '#fff',
        'line-width': 2
      }
    }
  ],
  featureLayers: [
    {
      'id': 'pister_outline',
      'type': 'line',
      'source': 'features',
      'filter': ['==', ['get', 'feature_type'], 'slope'],
      'minzoom': 10,
      'layout': {},
      'paint': {
        'line-color': '#fff',
        'line-width': [
          'interpolate', ['linear'], ['zoom'],
          13, 5,
          15, 9
        ]
      }
    },
    {
      'id': 'pister',
      'type': 'line',
      'source': 'features',
      'filter': ['==', ['get', 'feature_type'], 'slope'],
      'minzoom': 10,
      'layout': {},
      'paint': {
        'line-color': [
          'case',
          ['==', ['get', 'open'], false], '#acacac',
          [
            'match',
            ['get', 'grading'],
            'green', '#00943C',
            'blue', '#0B9BE6',
            'red', '#E40513',
            'ungroomed', '#D7923F',
            '#000'
          ]
        ],
        'line-width': [
          'interpolate', ['linear'], ['zoom'],
          13, 2,
          15, 5
        ],
        'line-dasharray': [
          'match',
          ['get', 'type'],
          'transport', ['literal', [3, 2]],
          ['literal', [1]]
        ]
      }
    },
    {
      'id': 'pister_label',
      'type': 'symbol',
      'source': 'features',
      'filter': [
        'all',
        ['==', ['get', 'feature_type'], 'slope'],
        ['==', ['get', 'badge'], 1]
      ],
      'minzoom': 12,
      'layout': {
        'symbol-placement': 'line',
        'text-rotation-alignment': 'viewport',
        'icon-image': [
          'case',
          ['==', ['get', 'open'], false],
          [
            'match',
            ['get', 'grading'],
            'green', 'piste_green_closed',
            'blue', 'piste_blue_closed',
            'red', 'piste_red_closed',
            'ungroomed', 'piste_ungroomed_closed',
            'piste_black_closed'
          ],
          [
            'match',
            ['get', 'grading'],
            'green', 'piste_green',
            'blue', 'piste_blue',
            'red', 'piste_red',
            'ungroomed', 'piste_ungroomed',
            'piste_black'
          ]
        ],
        'icon-allow-overlap': true,
        'icon-rotation-alignment': 'viewport',
        'text-field': ['get', 'number'],
        'text-size': 10,
        'text-font': ['Open Sans Bold']
      },
      'paint': {
        'text-color': '#fff'
      }
    },
    {
      'id': 'liftar_outline',
      'type': 'line',
      'source': 'features',
      'filter': ['==', ['get', 'feature_type'], 'lift'],
      'layout': {},
      'paint': {
        'line-color': '#fff',
        'line-width': [
          'interpolate', ['linear'], ['zoom'],
          13, 5,
          15, 9
        ]
      }
    },
    {
      'id': 'liftar',
      'type': 'line',
      'source': 'features',
      'filter': ['==', ['get', 'feature_type'], 'lift'],
      'layout': {},
      'paint': {
        'line-color': [
          'case',
          ['==', ['get', 'open'], false],
          '#acacac',
          '#f68325'
        ],
        'line-width': [
          'interpolate', ['linear'], ['zoom'],
          13, 3,
          15, 6
        ],
        'line-opacity': [
          'case',
          ['==', ['get', 'open'], false], 0.4,
          1
        ]
      }
    },
    {
      'id': 'liftar_berg',
      'type': 'symbol',
      'source': 'features',
      'filter': ['==', ['get', 'feature_type'], 'lift_end'],
      'layout': {
        'icon-image': [
          'case',
          ['==', ['get', 'open'], false],
          'lift_top_closed',
          'lift_top'
        ],
        'icon-allow-overlap': true,
        'icon-size': ['step', ['zoom'], 0.5, 12, 1]
      },
      'paint': {
      }
    },
    {
      'id': 'liftar_dal',
      'type': 'symbol',
      'source': 'features',
      'filter': ['==', ['get', 'feature_type'], 'lift_base'],
      'minzoom': 12,
      'layout': {
        'icon-image': [
          'case',
          ['==', ['get', 'open'], false],
          'lift_base_closed',
          'lift_base'
        ],
        'icon-allow-overlap': true,
        'text-allow-overlap': true,
        'text-optional': true,
        'text-field': ['get', 'designatio'],
        'text-size': 10,
        'text-font': ['Open Sans Bold']
      },
      'paint': {
        'text-color': '#fff'
      }
    },
    {
      'id': 'liftar_dal_far',
      'type': 'symbol',
      'source': 'features',
      'filter': ['==', ['get', 'feature_type'], 'lift_base'],
      'maxzoom': 12,
      'layout': {
        'icon-image': [
          'case',
          ['==', ['get', 'open'], false],
          'lift_top_closed',
          'lift_top'
        ],
        'icon-allow-overlap': true,
        'icon-size': 0.5
      },
      'paint': {
        'text-color': '#fff'
      }
    },
    {
      'id': 'weather_stations',
      'type': 'symbol',
      'source': 'weather_stations',
      'minzoom': 12,
      'layout': {
        'icon-image': 'white',
        'icon-allow-overlap': true,
        'text-allow-overlap': true,
        'text-optional': true,
        'icon-text-fit': 'both',
        'icon-text-fit-padding': [0, 3, 0, 3],
        'icon-offset': ['literal', [0, 20]],
        'text-field': [
          'concat',
          ['get', 'temperature'],
          ' ',
          ['get', 'wind_range']
        ],
        'text-size': 10,
        'text-font': ['Open Sans Bold']
      },
      'paint': {
        'text-color': '#000',
        'text-translate': ['literal', [0, 20]],
        'text-translate-anchor': 'viewport'
      }
    }
  ]
}
