import { Controller } from "@hotwired/stimulus"
import mapboxgl from 'mapbox-gl';
import mapConfig from '../config/map';

mapboxgl.accessToken = 'pk.eyJ1Ijoia29saXp6IiwiYSI6ImFmZDc0NjIyZWE4YjNjNTkyMzljOWIwODM2NmJjOWVjIn0.9t3ErtCezxsu-DujT6Bh9g';

export default class MapController extends Controller {
  static targets = [ "areasJson", "mapContainer" ];

  initialize() {
    this.preloadedGeoJson = {};
    this.areas = JSON.parse(this.areasJsonTarget.innerText);

    this.map = window.map = this.createMap();
    this.setupMapPosition();

    Promise.all([
      this.preloadGeoJsonSources(),
      this.awaitStyleLoaded()
    ])
      .then(() => this.setupMapStyleAdditions());

    this.map.on('moveend', () => this.updateLocationHash());
  }

  updateLocationHash() {
    const center = this.map.getCenter();
    const zoom = this.map.getZoom();
    const bearing = this.map.getBearing();
    const pitch = this.map.getPitch();
    history.replaceState(null, '', `#@${center.lat},${center.lng},${zoom},${bearing},${pitch}`);
  }

  // Internal

  setupMapPosition() {
    if (document.location.hash.startsWith('#@')) {
      const parameters = document.location.hash.replace('#@', '').split(',').map((parameter) => Number.parseFloat(parameter));

      this.map.setCenter([parameters[1], parameters[0]]);
      this.map.setZoom(parameters[2]);
      this.map.setBearing(parameters[3]);
      this.map.setPitch(parameters[4]);
    }
  }

  preloadGeoJsonSources() {
    const loadPromises = [];

    for (const [id, source] of Object.entries(mapConfig.sources)) {
      if (source.type != 'geojson') { continue; }

      loadPromises.push(
        fetch(source.data)
          .then((response) => response.json())
          .then((data) => {
            this.preloadedGeoJson[id] = data;
          })
      );
    }

    return Promise.all(loadPromises);
  }

  createMap() {
    return new mapboxgl.Map({
      container: this.mapContainerTarget,
      style: mapConfig.map.style,
      bounds: mapConfig.map.bounds,
      maxBounds: mapConfig.map.bounds,
      projection: 'globe'
    });
  }

  setupMapStyleAdditions() {
    for (const [id, source] of Object.entries(mapConfig.sources)) {
      if (source.type == 'geojson') {
        this.map.addSource(id, {
          ...source,
          data: this.preloadedGeoJson[id]
        });

        continue;
      }

      this.map.addSource(id, source);
    }

    mapConfig.baseLayers.forEach((layer) => {
      this.map.addLayer(layer);
    });

    mapConfig.featureLayers.forEach((layer) => {
      this.map.addLayer(layer);
    });

    const areaMarkerTemplate = document.getElementById('area-badge');
    this.areas.features.forEach((feature) => {
      const element = areaMarkerTemplate.cloneNode(true);
      element.id = '';
      element.querySelector('.title').innerText = feature.properties.name;
      const marker = new mapboxgl.Marker(element)
        .setLngLat(feature.geometry.coordinates)
        .addTo(this.map);
    });
  }

  awaitStyleLoaded() {
    return new Promise((resolve) => this.map.on('style.load', resolve))
  }
}
